import _ from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../actions/contracts';
import { debug } from '../utils/debug';
import { regional_settings } from '../constants';
import { locale_code } from '../constants/i18n';
import Settings from "../settings";
import { i18n } from "../config";

import { dispatchNewRoute} from '../utils/http_functions';

import { ContentHeader } from './ContentHeader';

import { Notification } from './Notification';
import { LoadingAnimation } from './LoadingAnimation';
import { SmartTable } from './SmartTable';

import { Button } from '@material-ui/core';
import { ArrowForward, ArrowBack, Receipt, ZoomIn, ErrorOutline } from '@material-ui/icons';

function mapStateToProps(state) {
    return {
        data: state.contracts,
        offset: state.contracts.offset_items,
        page_items: state.contracts.page_items,
        token: state.auth.token,
        loaded: state.contracts.loaded,
        isFetching: state.contracts.isFetching,
        message_text: state.contracts.message_text,
        filtered: state.contracts.filtered
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actionCreators, dispatch);
}

const style = {
    buttonAdd: {
        marginRight: 20,
    },
    buttonPosition: {
        textAlign: 'right',
    },
    table: {
        marginTop: 20,
    },
    iconBefore: {
        marginRight: '10px',
    },
};

class ContractsView extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            message_text: null,
            offset: 0,
            page_items: _.get(Settings, "contracts.itemsPerPage", 100),
        };
    }

    componentDidMount() {
        if ((_.isEmpty(this.props.data.items) && !this.props.loaded) || (_.isEmpty(this.props.data.items) && this.props.filtered)) {
            this.fetchData();
        }
    }

    fetchData(initial=true, offset=0, filter=null) {
        const token = this.props.token;
        this.props.fetchContracts(token, initial, offset, filter);
    }

    refreshData() {
        this.fetchData(false);
        this.setState({
            message_open: true,
        });
    }

    addContracts() {
        dispatchNewRoute(i18n.t('common:url.newElectricityContract'));
    }

    goToInvoice = (contract) => {
         this.props.filterContractsInvoices(contract);
    };

    goToContract = (id) => {
        const contract = this.props.data.items.filter((c) => c.name === id)[0];
        this.props.viewContract(contract);
        dispatchNewRoute(i18n.t('common:url.alt_contracts_detail'))
    };

    previous_page = () => {
        const offset = this.props.offset - this.props.page_items;
        this.setState({offset: offset});
        this.fetchData(false, offset);
    };

    next_page = () => {
        const offset = this.state.offset + this.state.page_items;
        this.setState({offset: offset});
        this.fetchData(false, offset);
    };

    onSearch = (searchText) => {
        this.fetchData(false, 0, searchText);
    };

    JSON_to_arrays(contracts) {
        const showProduct = _.get(Settings, 'contracts.showProduct', true);

        const header = [
            { title: ''},
            { title: i18n.t('common:text.contracts_contract') },
            { title: i18n.t('common:text.contracts_cups') },
            { title: i18n.t('common:text.contracts_direction') },
        ];

        if (showProduct) {
            header.push({ title: i18n.t('common:text.contracts_product') })
        }

        header.push(
            { title: i18n.t('common:text.contracts_power') },
            { title: i18n.t('common:text.contracts_state') },
        )

        // True if debt is different from 0 on all contracts.
        const hasDebt = contracts ? contracts.filter(c => c.amount_debt > 0).length !== 0 : false;

        header.push({ title: i18n.t('common:text.contracts_payment') })

        header.push(
            { title: i18n.t('common:text.contracts_actions') }
        )

        const goToInvoice = this.goToInvoice;
        const goToContract = this.goToContract;

        const content = contracts ? contracts.map( function(contract, index) {

            const invoices_button = (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={!contract.name}
                    title="Ver facturas relacionadas"
                    onClick={() => goToInvoice(contract)}
                    style={{marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden'}}
                >
                    { <Receipt style={style.iconBefore}/> }
                    {i18n.t('common:text.contracts_view_invoices_button_button')}
                </Button>
            );

            const contract_button = (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    disabled={!contract.name}
                    title="Ver detalle del contrato"
                    onClick={() => goToContract(contract.name)}
                    style={{marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden'}}
                >
                    { <ZoomIn style={style.iconBefore}/> }
                    {i18n.t('common:text.contracts_view_contracts_button_button')}
                </Button>
            );

            let contractStatus;
            switch (contract.state) {
                case 'esborrany':
                        contractStatus = i18n.t('common:text.contract_status_draft');
                        if (!contract.sign_date) {
                            contractStatus = i18n.t('common:text.contract_status_signature_pending');
                        }
                        break;
                case 'activa':
                        contractStatus = i18n.t('common:text.contract_status_open');
                        break;
                case 'baixa':
                        contractStatus = i18n.t('common:text.contract_status_closed');
                        break;
                default:
                        contractStatus = i18n.t('common:text.contract_status_closed');
                        break;
            }

            const hasDebt = contract.amount_debt > 0;
            const contractPayment = hasDebt ? `${i18n.t('common:text.contract_debt')}  (${contract.amount_debt.toLocaleString(locale_code)} €)` : '';
            const contractedPowers = (contract.contracted_power_period && contract.contracted_power_period.length) ? contract.contracted_power_period.reduce((accumulator, currentValue) => {
                return `${accumulator} ${currentValue.period}: ${currentValue.power.toLocaleString(locale_code)} ${regional_settings.potence_unit}`;
            }, '') : '';

            const values = [
                <div>{hasDebt ? <ErrorOutline style={{color: '#f00'}}/> : null}</div>,
                contract.name,
                contract.cups.name,
                contract.cups.full_address
            ];
            if (showProduct) {
                if (contract.pricelist?.nom_comercial){
                    values.push((contract.pricelist && contract.pricelist.nom_comercial) ? contract.pricelist.nom_comercial : "");
                }
                else {
                    values.push((contract.pricelist && contract.pricelist.name) ? contract.pricelist.name : "");
                }
            }
            values.push(
                contractedPowers,
                contractStatus,
            );

            values.push(contractPayment);
            values.push(
                <div>
                    {invoices_button}
                    {contract_button}
                </div>
            );

            return values;
        }) : [];

        return {
            header,
            content
        };
    }

    render() {
        const {data, loaded} = this.props;
        const contracts = data.items;

        const adaptedData =
            (loaded) &&
                this.JSON_to_arrays(contracts);

        const start_items = data.offset_items;
        const end_items = Math.min(start_items + data.page_items, data.total_items);

        return (
            <div>
                <Notification
                    message={this.props.message_text}
                    open={this.state.message_open}
                />

        		<ContentHeader
        		    title={i18n.t('common:text.contracts_view_title')}
        		    addButton={_.get(Settings, "features.newElectricityContractation", false)}
        		    addClickMethod={() => this.addContracts()}

        		    refreshButton={true}
        		    refreshClickMethod={() => this.refreshData()}
                    onSearch={this.onSearch}
                />
            {
                this.props.loaded?
                <div style={style.table}>
                    <SmartTable
                        header={adaptedData.header}
                        data={adaptedData.content}
                    />

                </div>
            :
                <div>
                    <LoadingAnimation />
                </div>
            }
            {(data.page_items < data.total_items) &&
                    <div style={{textAlign: "center"}}>
                        {(start_items > 0) &&
                            <Button
                                variant={'text'}
                                disabled={data.isFetching}
                                onClick={() => this.previous_page()}
                            >
                                {<ArrowBack/>}
                                {"Anterior"}
                            </Button>
                        }
                        <span>Mostrando de {start_items} a {end_items} de {data.total_items}</span>
                        {(end_items <= data.total_items) &&
                            <Button
                                variant={'text'}
                                disabled={data.isFetching}
                                onClick={() => this.next_page()}
                            >
                                {"Siguiente"}
                                {<ArrowForward/>}
                            </Button>
                        }
                    </div>
                    }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsView);

ContractsView.propTypes = {
    fetchContracts: PropTypes.func,
    loaded: PropTypes.bool,
    data: PropTypes.any,
    token: PropTypes.string,
};
